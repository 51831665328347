import React from "react";

const UniethIcon: React.FC = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.6"
      d="M6.63354 7.34884L12.0001 2V9.44186L6.63354 7.34884Z"
      fill="black"
    />
    <path
      opacity="0.8"
      d="M12.0001 9.44187L6.63354 7.34885L12.0001 12.9302V9.44187Z"
      fill="black"
    />
    <path
      opacity="0.8"
      d="M11.9999 22V15.9535L5 8.97675L11.9999 22Z"
      fill="black"
    />
    <path
      opacity="0.6"
      d="M11.9999 22L5 8.97675V16.1861L11.9999 22Z"
      fill="black"
    />
    <path
      opacity="0.4"
      d="M17.3665 7.34884L11.9999 2V9.44186L17.3665 7.34884Z"
      fill="black"
    />
    <path
      d="M11.9999 9.44187L17.3665 7.34885L11.9999 12.9302V9.44187Z"
      fill="black"
    />
    <path d="M12.0001 22V15.9535L19 8.97675L12.0001 22Z" fill="black" />
    <path
      opacity="0.6"
      d="M12.0001 22L19 8.97675V16.1861L12.0001 22Z"
      fill="black"
    />
  </svg>
);

export default UniethIcon;
