import * as React from "react";
const PtWethIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7446 18.0445C18.7446 18.0445 18.7446 18.064 18.7446 18.0738C18.7446 18.162 18.6956 18.2403 18.6271 18.2893L12.605 22.0004C12.5267 22.0493 12.419 22.0493 12.3406 22.0004L6.36757 18.3382C6.28924 18.2893 6.25007 18.2109 6.25007 18.1228V18.1032V18.0836V13.501C6.25007 13.501 6.25007 13.4912 6.25007 13.4814C6.25007 13.4129 6.26965 13.3443 6.31861 13.2954C6.39695 13.2072 6.53403 13.1877 6.63195 13.2464L12.4973 16.654L18.3137 13.2856C18.3137 13.2856 18.3333 13.2758 18.3431 13.266C18.3823 13.2366 18.441 13.217 18.4998 13.217C18.6368 13.217 18.7544 13.3345 18.7544 13.4716V18.0151C18.7544 18.0151 18.7544 18.0347 18.7544 18.0542L18.7446 18.0445ZM6.75925 17.6724L8.49242 16.6246L6.75925 14.2354V17.6724ZM8.78618 17.0359C8.30637 17.3296 6.98446 18.1228 6.98446 18.1228L11.6552 20.9918L8.78618 17.0359ZM13.3688 20.9429L18.0102 18.0836L16.2182 17.0065L13.3688 20.9429ZM16.512 16.5953L18.2452 17.6332V14.206L16.512 16.5953ZM12.37 17.1632L7.41531 14.2844L12.4973 21.2856L17.5402 14.3137L12.6246 17.1632C12.5463 17.2121 12.4484 17.2121 12.37 17.1632ZM6.49487 12.2672C6.35778 12.2672 6.25007 12.1497 6.25007 12.0126V6.05914C6.25007 6.05914 6.25007 6.02977 6.25007 6.01997C6.25007 6.01018 6.25007 5.9906 6.25007 5.98081C6.25007 5.89268 6.29903 5.81434 6.36757 5.76538L12.3896 2.07383C12.4679 2.02487 12.5756 2.02487 12.654 2.07383L18.6271 5.71642C18.7054 5.76538 18.7446 5.84372 18.7446 5.93185C18.7446 5.93185 18.7446 5.93185 18.7446 5.94164C18.7446 5.95143 18.7446 5.94164 18.7446 5.95143V11.9833C18.7446 12.091 18.676 12.1791 18.5879 12.2183L12.6246 15.4986C12.5463 15.5377 12.4581 15.5377 12.3798 15.4986L6.49487 12.2672ZM6.75925 6.42144V11.2097L8.90368 7.71398L6.75925 6.42144ZM18.2354 6.38227L16.0714 7.69439L18.2354 11.2293V6.39207V6.38227ZM15.807 7.25376C16.3651 6.92083 18.0004 5.92205 18.0004 5.92205L13.1925 2.99427L15.807 7.26355V7.25376ZM11.7629 3.04323L6.99425 5.98081L9.16806 7.29292L11.7629 3.04323ZM6.91592 11.9245L12.4973 14.9992L18.0787 11.9245L12.4973 2.82781L6.91592 11.9245Z"
      fill="black"
    />
  </svg>
);
export default PtWethIcon;
