import * as React from "react";
const ArbitrumIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#000"
      d="m20.231 6.443-7.458-4.238a1.555 1.555 0 0 0-1.542 0L3.77 6.447C3.295 6.717 3 7.216 3 7.756v8.484c0 .54.295 1.043.769 1.313l3.104 1.764v-.001l.195.11 4.467-12.055a.167.167 0 0 0-.162-.22H9.482a.332.332 0 0 0-.316.216l-3.618 9.767-1.148-.653a.273.273 0 0 1-.141-.237V7.756c0-.099.054-.189.141-.238l7.463-4.242A.275.275 0 0 1 12 3.24c.05 0 .096.013.141.037l7.463 4.242c.087.05.141.14.141.238v8.484a.273.273 0 0 1-.141.237l-1.136.646-2.99-8.07a.168.168 0 0 0-.316 0l-.985 2.654a.312.312 0 0 0 0 .23l2.334 6.3-.776.442-1.91-5.16a.168.168 0 0 0-.316 0l-.985 2.655a.312.312 0 0 0 0 .23l1.254 3.388-1.636.931a.292.292 0 0 1-.283 0l-1.62-.921 4.603-12.427a.167.167 0 0 0-.157-.225h-1.891a.332.332 0 0 0-.316.217L7.845 19.868l.278.159h-.001l3.11 1.768a1.555 1.555 0 0 0 1.537 0l7.462-4.242c.478-.27.769-.769.769-1.313V7.756c0-.54-.295-1.043-.769-1.313Z"
    />
  </svg>
);
export default ArbitrumIcon;
