import * as React from "react";
const PendleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M12.822 17.91a7.968 7.968 0 0 1-7.447-3.49 4.405 4.405 0 1 0 7.447 3.49Z"
      opacity={0.6}
    />
    <path
      fill="#000"
      d="M8.905 2.623v10.593a4.405 4.405 0 0 1 3.917 4.694 7.968 7.968 0 0 1-7.447-3.49 4.39 4.39 0 0 1 2.577-1.204V3.102c.305-.18.623-.34.953-.48Z"
    />
    <g fill="#000" opacity={0.4}>
      <path d="M12.822 17.91A7.977 7.977 0 1 0 8.905 2.623v10.593a4.405 4.405 0 0 1 3.917 4.694ZM7.952 3.102a7.972 7.972 0 0 0-3.928 6.874c0 1.645.498 3.174 1.352 4.444a4.39 4.39 0 0 1 2.576-1.204V3.102Z" />
    </g>
  </svg>
);
export default PendleIcon;
