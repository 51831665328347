import * as React from "react";
const RadiantIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M15.13 13.297 23 12l-7.87-1.297 4.648-6.481-6.481 4.648L12 1l-1.296 7.87-6.482-4.648 4.648 6.481L1 12l7.87 1.297-4.648 6.481 6.482-4.648L12 23l1.297-7.87 6.481 4.648-4.648-6.481Z"
    />
  </svg>
);
export default RadiantIcon;
