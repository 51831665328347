import * as React from "react";
const EthIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path fill="#000" d="M12 2 6 12l6 4V2Z" opacity={0.6} />
    <path fill="#000" d="m12 2 6 10-6 4V2Z" />
    <path fill="#000" d="m12 17.5-6-4 6 8.5v-4.5Z" opacity={0.8} />
    <path fill="#000" d="m12 17.5 6-4-6 8.5v-4.5Z" />
  </svg>
);
export default EthIcon;
