import * as React from "react";
const EthPlusIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.186 4.66665H16.1148V7.20788H18.656V10.1367H16.1148V12.6779H13.186V10.1367H10.6448V7.20788H13.186V4.66665ZM13.686 5.16665V7.70788H11.1448V9.63668H13.686V12.1779H15.6148V9.63668H18.156V7.70788H15.6148V5.16665H13.686Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.436 5.0845L11.5777 2L5.43799 12.187L11.5777 15.8144L17.715 12.187L16.3292 9.88668H15.8648V12.4279H13.436V9.88668H10.8948V7.45788H13.436V5.0845ZM17.7197 13.3502L11.5777 16.9777L5.43799 13.3502L11.5777 22L17.7197 13.3502Z"
      fill="black"
    />
  </svg>
);
export default EthPlusIcon;
