import React from "react";

import { AvailableTokens, TOKEN_ICONS } from "../token-icons";

interface Props extends React.HTMLAttributes<SVGSVGElement> {
  token: string;
}

export const TokenIcon = ({ token, ...rest }: Props) => {
  const Icon: React.FC<React.SVGProps<SVGSVGElement>> | undefined =
    TOKEN_ICONS[token as AvailableTokens];

  if (!Icon) {
    return null;
  }

  return <Icon {...rest} width={24} widths={24} />;
};
