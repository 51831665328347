import * as React from "react";
const KnoxIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M5.4179 19.5409V4.4591H3.50871V19.5409H5.4179ZM18.2617 19.5409L11.3859 11.2123L10.4913 11.9096L16.793 19.5409H18.2617ZM21.4125 21H16.0588L9.30306 12.8264L6.92656 14.6729V21H2V3H6.92656V12.7876L19.5834 3H22L12.5741 10.2955L16.9933 15.6477L21.4125 21Z"
      fill="black"
    />
  </svg>
);
export default KnoxIcon;
