import * as React from "react";
const MusdIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.8735 4.54527V2H13.0553V4.54527H16.3274V8.90884H11.9639V10.7418C11.9717 11.5383 12.6199 12.1817 13.4183 12.1817V12.1846H16.3271V19.821H13.0553V22H10.8735V19.821H7.59998V15.4574H11.9635V13.6081C11.9485 12.8178 11.3031 12.1817 10.5092 12.1817L7.6003 12.1816V4.54527H10.8735Z"
      fill="#000"
    />
  </svg>
);
export default MusdIcon;
