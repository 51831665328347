import * as React from "react";
const GmxIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M22 19 12.015 4.5 2 19h13.954l-3.939-5.534-1.953 2.876H7.985l4.032-5.781L17.844 19 22 19Z"
    />
  </svg>
);
export default GmxIcon;
