import * as React from "react";
const UsdbIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0331 18.0005L9.74627 22H7.53246L8.80582 18.0005H3.21545L3.97711 15.6517H9.5536L10.3962 13.0052H7.25756L7.26104 12.9943L5.28025 11.5393L6.41885 8.04482L8.41026 9.50107L8.01942 10.7065L11.1306 10.6984L11.9749 8.04657L6.41885 8.04482L9.49693 5.73533L12.7108 5.73527L13.9 2H16.1813L14.9795 5.73523L20.7502 5.73513L20 8.04911L14.2356 8.04729L13.3844 10.6926L17.1404 10.6828L18.9749 12.0301L17.5172 16.5248L14.5513 18.0005H11.0331ZM11.7888 15.6517H15.3125L16.1711 13.0052H12.6404L11.7888 15.6517Z"
      fill="black"
    />
  </svg>
);
export default UsdbIcon;
