import * as React from "react";
const Usdt = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.35577 5H17.8756C18.1504 5 18.4044 5.14243 18.5414 5.37349L21.8976 11.0306C22.0717 11.324 22.02 11.6943 21.7717 11.9322L12.5337 20.7849C12.2344 21.0717 11.7531 21.0717 11.4538 20.7849L2.22829 11.9441C1.97427 11.7007 1.9267 11.3196 2.1135 11.0244L5.701 5.35534C5.84072 5.13455 6.08865 5 6.35577 5ZM16.3882 7.52572V9.11303H13.1066V10.2136C15.4114 10.3312 17.1405 10.8142 17.1533 11.3931L17.1533 12.6001C17.1404 13.1791 15.4114 13.6621 13.1066 13.7797V16.4805H10.9276V13.7797C8.6229 13.6621 6.89375 13.1791 6.88095 12.6001L6.88099 11.3931C6.89383 10.8142 8.6229 10.3312 10.9276 10.2136V9.11303H7.6461V7.52572H16.3882ZM12.0171 12.9505C14.4767 12.9505 16.5325 12.5421 17.0355 11.9967C16.6089 11.5341 15.0657 11.1701 13.1066 11.0701V12.2224C12.7555 12.2403 12.391 12.2497 12.0171 12.2497C11.6433 12.2497 11.2788 12.2403 10.9276 12.2224V11.0701C8.96852 11.1701 7.42534 11.5341 6.99871 11.9967C7.5018 12.5421 9.55758 12.9505 12.0171 12.9505Z"
      fill="black"
    />
  </svg>
);
export default Usdt;
