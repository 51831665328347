import React from "react";

const PzethIcon: React.FC = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0501 2L16.9289 9.46909L12.0501 12.248L7.17141 9.46909L12.0501 2ZM8.6638 9.11421L12.0501 3.93247L15.4365 9.11421L12.0501 11.0424L8.6638 9.11421Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25504 10.5469L11.9984 13.7899L17.7417 10.5469L17.8967 10.7835C19.2621 12.8524 19.3408 15.4146 18.2402 17.5125C17.1271 18.3375 15.3767 18.5059 12.2567 16.3213C9.24441 14.2122 6.88716 14.212 5.01983 14.9456C4.90914 13.5203 5.26083 12.0583 6.10005 10.7835L6.25504 10.5469Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1634 18.3476C17.0542 19.2203 15.4299 19.6483 12.24 17.4148C9.2244 15.3034 6.93217 15.1036 5.06387 15.8395C5.16749 17.4453 5.85743 18.8082 7.09782 20.013C9.83003 22.6623 14.2626 22.6623 16.9992 20.013C17.5729 19.4568 17.8284 19.0259 18.1634 18.3476Z"
      fill="black"
    />
  </svg>
);

export default PzethIcon;
