import React from "react";

const RsethIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.00105 11.1248L12.2453 4.19738H12.2546V9.9609H12.2453V9.97021L9.89979 11.013L7.37744 12.1397L7.66598 11.6741L7.85213 11.3575H7.86143L8.00105 11.1248Z"
      fill="black"
    />
    <path
      d="M12.2546 17.3073H12.2453V16.9721L8.73634 14.933L7.37744 13.0522L12.2546 15.8827V17.3073Z"
      fill="black"
    />
    <path
      d="M12.2453 16.9721V17.3073L9.14587 15.5103L8.73634 14.933L12.2453 16.9721Z"
      fill="black"
    />
    <path
      d="M9.14587 15.5103L12.2451 19.8027L12.2453 17.3073L9.14587 15.5103Z"
      fill="black"
    />
    <path
      opacity="0.8"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.132 13.0522L12.2549 15.8827V16.9721L15.7731 14.933L17.132 13.0522ZM15.7731 14.933L12.2549 16.9721V17.3166V19.8027L15.3543 15.5103L15.7731 14.933Z"
      fill="black"
    />
    <path
      opacity="0.6"
      d="M16.8435 11.6741L16.648 11.3575L16.5084 11.1248L12.2549 4.19738V9.22533V9.39292V9.40224V9.6257V9.9609L14.6004 11.013L17.132 12.1397L16.8435 11.6741Z"
      fill="black"
    />
    <g opacity="0.5">
      <path
        d="M8.00111 11.1248L12.2453 4.19742V2.37246L6.45605 11.8138L8.00111 11.1248Z"
        fill="black"
      />
      <path
        d="M9.14593 15.5103L7.08896 14.3184L12.2453 21.46V19.8026L9.14593 15.5103Z"
        fill="black"
      />
      <path
        d="M12.2546 9.96096L14.6001 11.0131L17.1318 12.1397L12.2546 14.9796V9.96096Z"
        fill="black"
      />
    </g>
    <g opacity="0.4">
      <path
        d="M12.2454 2.37244L6.45607 11.8138L8.00112 11.1248L7.86151 11.3576L6 12.1862L12.2454 2V2.37244Z"
        fill="black"
      />
      <path
        d="M12.2546 2.37246L18.0439 11.8138L16.5082 11.1248L12.2546 4.19742V2.37246Z"
        fill="black"
      />
      <path
        d="M7.3775 12.1397L12.2453 14.9703V9.97028L9.89984 11.0131L7.3775 12.1397Z"
        fill="black"
      />
      <path
        d="M12.2546 19.8026L15.3541 15.5103L17.4203 14.3185L12.2546 21.46V19.8026Z"
        fill="black"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M16.5082 11.1248L16.6478 11.3576L18.5 12.1862L12.2546 2V2.37244L18.0439 11.8138L16.5082 11.1248Z"
        fill="black"
      />
      <path
        d="M9.14595 15.5102L8.73642 14.9329L6 13.3501L12.2454 22V21.46L7.08898 14.3184L9.14595 15.5102Z"
        fill="black"
      />
      <path
        d="M17.1318 12.1397L16.8433 11.6742L18.0439 12.2142L12.2546 15.5755V14.9796L17.1318 12.1397Z"
        fill="black"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M16.6478 11.3576L16.8433 11.6742L18.0439 12.2142L12.2546 15.5755V15.8176L18.5 12.1863L16.6478 11.3576Z"
        fill="black"
      />
      <path
        d="M15.7729 14.9329L15.354 15.5102L17.4203 14.3184L12.2546 21.46V22L18.5 13.3501L15.7729 14.9329Z"
        fill="black"
      />
      <path
        d="M7.66604 11.6741L7.3775 12.1397L12.2453 14.9702V15.5755L6.45605 12.2142L7.66604 11.6741Z"
        fill="black"
      />
    </g>
    <path
      opacity="0.1"
      d="M7.66605 11.6742L7.8522 11.3576L6 12.1863L12.2454 15.8176V15.5755L6.45607 12.2142L7.66605 11.6742Z"
      fill="black"
    />
  </svg>
);

export default RsethIcon;
