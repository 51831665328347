import * as React from "react";
const UsdcIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm2.285 2.959c-.226-.072-.41.061-.41.298v.582c0 .16.12.34.269.394a6.26 6.26 0 0 1 4.106 5.871 6.26 6.26 0 0 1-4.106 5.87.418.418 0 0 0-.269.395v.582c0 .237.184.37.41.299a7.503 7.503 0 0 0 0-14.291Zm-4.16.298c0-.237-.184-.37-.41-.298a7.503 7.503 0 0 0 0 14.29c.226.073.41-.061.41-.298v-.582c0-.159-.12-.34-.269-.394a6.26 6.26 0 0 1-4.106-5.87 6.26 6.26 0 0 1 4.106-5.872.451.451 0 0 0 .269-.394v-.582Zm2.188 1.847h-.626a.312.312 0 0 0-.312.313v.968c-1.239.176-2.04 1-2.04 2.058 0 1.37.828 1.9 2.575 2.136 1.185.194 1.522.45 1.522 1.124 0 .674-.572 1.124-1.379 1.124-1.091 0-1.45-.478-1.583-1.096a.315.315 0 0 0-.306-.253h-.713c-.18 0-.32.162-.289.339.182 1.04.85 1.803 2.213 1.988v.987c0 .172.14.312.313.312h.624c.173 0 .313-.14.313-.312v-.988c1.29-.204 2.115-1.103 2.115-2.214 0-1.46-.889-1.951-2.605-2.186-1.267-.184-1.512-.48-1.512-1.073 0-.562.429-.96 1.256-.96.749 0 1.18.26 1.357.863.04.135.16.23.3.23h.658a.29.29 0 0 0 .287-.348c-.208-.952-.85-1.523-1.856-1.704v-.995a.312.312 0 0 0-.313-.313Z"
      clipRule="evenodd"
    />
  </svg>
);
export default UsdcIcon;
