const BlastIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.6953 9.95379L18.9107 11.856L18.8849 11.9082L21.4553 13.8104L20.0861 18.0841L16.2497 19.9994L1.57627 19.999L5.51597 7.52981L8.13815 9.51035L5.77433 16.937H17.2319L18.549 12.8723H10.3595L11.1474 10.3968H19.3369L20.422 7.00911H0L4.01722 3.9994H21.3908L24 5.91472L22.6953 9.95379Z"
      fill="black"
    />
  </svg>
);
export default BlastIcon;
