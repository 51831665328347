import PzethIcon from "@/icons/token/pzeth-icon";
import UniethIcon from "@/icons/token/unieth-icon";

import ArbitrumIcon from "../../../icons/token/arbitrum-icon";
import BlastIcon from "../../../icons/token/blast-icon";
import EthIcon from "../../../icons/token/eth-icon";
import EthPlusIcon from "../../../icons/token/ethplus-icon";
import EusdIcon from "../../../icons/token/eusd-icon";
import GmxIcon from "../../../icons/token/gmx-icon";
import KnoxIcon from "../../../icons/token/knox-icon";
import MusdIcon from "../../../icons/token/musd-icon";
import PendleIcon from "../../../icons/token/pendle-icon";
import PtWethIcon from "../../../icons/token/pt-weeth-icon";
import RadiantIcon from "../../../icons/token/radiant-icon";
import UsdbIcon from "../../../icons/token/usdb-icon";
import UsdcIcon from "../../../icons/token/usdc-icon";
import UsdeIcon from "../../../icons/token/usde-icon";
import Usdt from "../../../icons/token/usdt";
import WbtcIcon from "../../../icons/token/wbtc-icon";
import RsethIcon from "@/icons/token/rseth-icon";

export const TOKEN_ICONS = {
  ETH: EthIcon,
  WETH: EthIcon,
  ARB: ArbitrumIcon,
  USDC: UsdcIcon,
  "USDC.e": UsdcIcon,
  GMX: GmxIcon,
  BLAST: BlastIcon,
  "ETH+": EthPlusIcon,
  eUSD: EusdIcon,
  KNOX: KnoxIcon,
  ezETH: EthIcon,
  weETH: PtWethIcon,
  USDe: UsdeIcon,
  sUSDe: UsdeIcon,
  USDT: Usdt,
  WBTC: WbtcIcon,
  PENDLE: PendleIcon,
  MUSD: MusdIcon,
  RDNT: RadiantIcon,
  USDB: UsdbIcon,
  pzETH: PzethIcon,
  uniETH: UniethIcon,
  amphrETH: EthIcon,
  rsETH: RsethIcon,
  "PT-weETH-27JUN2024": PtWethIcon,
  "PT-ezETH-26DEC2024": EthIcon,
  "PT-USDe-29AUG2024": UsdeIcon,
  "PT-weETH-26SEP2024": PtWethIcon,
  "PT-USDe-25JUL2024": UsdeIcon,
  "PT-ezETH-26SEP2024": EthIcon,
  "PT-weETH-26DEC2024": PtWethIcon,
  "PT-uniETH-26SEP2024": UniethIcon,
  "PT-amphrETH-26SEP2024": EthIcon,
  "PT-pzETH-26SEP2024": PzethIcon,
  "PT-rsETH-26SEP2024": RsethIcon,
};

export type AvailableTokens = keyof typeof TOKEN_ICONS;
