import * as React from "react";
const WbtcIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M10.202 4.335a7.815 7.815 0 0 0-3.473 1.817l-.405-.441A6.753 6.753 0 0 1 7.99 4.45c.564-.3 1.16-.534 1.776-.7l.105-.027.127-.03a8.849 8.849 0 0 1 1.886-.23h.336a8.48 8.48 0 0 1 5.317 2.067l.053.048c.041.038.083.076.123.117l-.428.462a8.832 8.832 0 0 0-2.254-1.404l-.013-.006a7.256 7.256 0 0 0-.311-.123l-.077-.027a6.1 6.1 0 0 0-.399-.132l-.11-.031a7.815 7.815 0 0 0-3.919-.099Z"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M13.153 8.52c1.258.12 2.251.504 2.361 1.664l.002-.003c.085.844-.27 1.351-.835 1.639.93.221 1.51.774 1.394 2.013-.147 1.54-1.288 1.949-2.928 2.045v1.608h-.972V15.89H11.4v1.606h-.972v-1.63h-1.96l.191-1.163s.72.011.707 0a.344.344 0 0 0 .364-.322V9.998a.502.502 0 0 0-.551-.428c.01-.012-.707 0-.707 0V8.53h1.969v-1.6h.97v1.568c.26-.01.52-.01.777-.01V6.93h.965v1.59Zm-1.56 3.07c.666.012 2.109.037 2.109-.975 0-1.035-1.396-.998-2.076-.98a8.12 8.12 0 0 1-.197.003v1.95c.046 0 .102 0 .164.002Zm.022 3.125c.795.011 2.535.037 2.538-1.071v.002-.004.002c-.003-1.137-1.673-1.096-2.488-1.077-.091.002-.172.005-.238.005v2.14l.188.003Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      d="m6.187 6.69-.47-.412c-3.187 3.341-2.723 8.777 0 11.368l.48-.375a7.826 7.826 0 0 1-.01-10.581ZM18.257 6.262c.077.072.152.148.226.225h.004a8.512 8.512 0 0 1-.165 11.168l-.401-.484c2.85-3.355 2.34-7.713-.076-10.458l.412-.45ZM17.316 17.813c-.278.208-.554.399-.831.575a7.803 7.803 0 0 1-3.384 1.35c-.624.1-1.258.114-1.885.044a7.808 7.808 0 0 1-4.29-1.81l-.191-.14-.442.397c1.546 1.527 3.667 2.262 5.753 2.26h.16a8.478 8.478 0 0 0 5.282-2.025l.04-.038c.055-.05.109-.101.16-.154l-.372-.46Z"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M6.441 3.687A10 10 0 1 1 17.56 20.313 10 10 0 0 1 6.441 3.687Zm10.74.555a9.329 9.329 0 0 0-5.185-1.57 9.34 9.34 0 0 0-9.325 9.324 9.329 9.329 0 1 0 14.51-7.754Z"
      clipRule="evenodd"
    />
  </svg>
);
export default WbtcIcon;
