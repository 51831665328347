import * as React from "react";
const UsdeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M2 12C2 7.03802 5.54864 2.90489 10.2465 2V2.95446C6.06871 3.84503 2.93527 7.5566 2.93527 12C2.93527 16.4434 6.06871 20.155 10.2465 21.0456V22C5.54864 21.0951 2 16.962 2 12Z"
      fill="black"
    />
    <path
      d="M14.1392 2.00338V2.9582C18.3082 3.85538 21.4329 7.56277 21.4329 12C21.4329 16.4372 18.3082 20.1446 14.1392 21.0418V21.9966C18.8284 21.0849 22.3682 16.9558 22.3682 12C22.3682 7.04419 18.8284 2.91514 14.1392 2.00338Z"
      fill="black"
    />
    <path
      d="M15.4432 12.5165C15.0148 12.0934 14.4122 11.8078 13.6351 11.6597L10.9603 11.1678C10.512 11.0832 10.1583 10.9139 9.89933 10.6601C9.65024 10.4062 9.52573 10.0942 9.52573 9.72393V8.86712C9.52573 8.41229 9.6951 8.0526 10.0338 7.78816C10.3725 7.51312 10.8258 7.37563 11.3936 7.37563H13.0822C13.6102 7.37563 14.0336 7.55018 14.3524 7.8992C14.6712 8.24829 14.8306 8.71372 14.8306 9.2955H15.9065C15.9065 8.40699 15.6475 7.69297 15.1294 7.15349C14.6114 6.6034 13.929 6.32843 13.0822 6.32843H12.7385V4.70997H11.6626V6.32843H11.3936C10.497 6.32843 9.77476 6.56111 9.22686 7.02654C8.68894 7.48143 8.41995 8.09489 8.41995 8.86712V9.72393C8.41995 10.3798 8.62911 10.9245 9.04757 11.3582C9.46597 11.7919 10.0537 12.0775 10.8109 12.215L13.5006 12.6911C13.9589 12.7651 14.3175 12.9291 14.5765 13.1829C14.8455 13.4368 14.98 13.7436 14.98 14.1032V15.1187C14.98 15.5735 14.8007 15.9438 14.442 16.2294C14.0834 16.5044 13.6102 16.6419 13.0224 16.6419H11.3637C10.766 16.6419 10.2829 16.441 9.91424 16.039C9.55561 15.6264 9.37633 15.0923 9.37633 14.4364H8.30042C8.30042 15.0817 8.42494 15.6529 8.67397 16.15C8.93298 16.6367 9.29161 17.0174 9.74987 17.2925C10.2081 17.5569 10.7361 17.6892 11.3339 17.6892H11.6626V19.3076H12.7385V17.6892H13.0523C13.9589 17.6892 14.6911 17.4564 15.249 16.991C15.8068 16.515 16.0858 15.8909 16.0858 15.1187V14.1032C16.0858 13.4579 15.8716 12.9291 15.4432 12.5165Z"
      fill="black"
    />
  </svg>
);
export default UsdeIcon;
